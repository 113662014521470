@import '../../../../../styles/basics';

.bb-cta-secondary {
  &__container {
    display: flex;
    flex-wrap: wrap;
  }

  &__link {
    display: block;
    transition: background-color 0.2s;
    flex-basis: 100%;

    @include media-breakpoint-up(lg) {
      flex-basis: 50%;
    }

    &:hover,
    &:focus-visible {
      @include media-breakpoint-up(lg) {
        background-color: $turquoise-1200;
      }

      :global(.link-title__title) {
        text-decoration: underline;
      }
    }

    @include media-breakpoint-up(lg) {
      &:nth-child(odd),
      &:nth-child(even) {
        padding-left: 24px;

      }
      &:nth-child(odd) {
        margin-left: -24px;
      }
    }

    :global(.icon-stat) {
      align-items: center;

      .image-container {
        padding: 0;
      }

      img {
        height: 30px;
        max-width: 30px;

        @include media-breakpoint-up(md) {
          height: 86px;
          max-width: 86px;
        }
      }
    }
  }
}
